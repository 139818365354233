import { Component, OnInit, OnDestroy } from '@angular/core';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_themes from '@amcharts/amcharts4/themes/kelly.js';
import kelly from '@amcharts/amcharts4/themes/kelly.js';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bAllowance, stitches, proSubAnalysisColumns, proAnalysisHistoryColumns } from './pro-list';
import { ProSMVService } from './pro.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pro',
    templateUrl: './pro.component.html',
    styleUrls: ['./pro.component.sass']
})
export class ProComponent implements OnInit, OnDestroy {
    chartValues: any;
    spinner: boolean;
    factoryId: number;
    operationOptions: Array<any>;
    availableOperationList: Array<any>;
    operationId: number;
    sizeGridOptions: Array<any>;
    availableSizeGridList: Array<any>;
    sizeGridId: number;
    sizesOptions: Array<any>;
    availableSizeList: Array<any>;
    sizeId: Array<any>;
    bAllowance: Array<any> = bAllowance;
    stiches: Array<any> = stitches;
    proSMVCodeData: Array<any> = [];
    pickupData: Array<any> = [];
    foldData: Array<any> = [];
    lineUpData: Array<any> = [];
    machineData: Array<any> = [];
    mtmData: Array<any> = [];
    disposeData: Array<any> = [];
    cutData: Array<any> = [];
    factoryList: Array<any> = [];
    opAnalysis: any = {};
    proSubAnalysisData: Array<any> = [];
    proSubAnalysisColumn: any;
    proAnalysisHistoryColumns: any;
    operationName: any;
    createAnalysis: any = {};
    display = false;
    analysisHeaderId: number;
    chart: AmChart;
    calValue = '';
    showVideos = false;
    stageList: Array<any> = [];
    stageId: number;
    ischartZoom = false;
    viewType: string;
    documents: any[];
    proAuthData: any = {};
    sizeName: string;
    color = '';
    value = 25;
    selectedVideoLink: any;
    analysisFactoryId: number;
    operationAnalysisOptions: any[];
    analysisOperationId: any;
    analysisOperation: any;
    opAnalysisHistory: any;
    sizeGridValue: any;
    sizeValue: any;
    defaultFrequency = 1;
    selectAll = false;
    selectedItems: any[];
    rowIndexArray: Array<any> = [];
    isLocked: boolean = false;
    availableCopySizeList: Array<{ sizeId: number; sizeName: string }> = [];
    unavailableCopySizeList: Array<{ sizeId: number; sizeName: string }> = [];

    selectedSizes = {
        oldSizeId: null,
        newSizeId: null,
    };

    constructor(
        private AmCharts: AmChartsService,
        public ngxSmartModalService: NgxSmartModalService,
        private toaster: ToastrService,
        public sharedService: SharedService,
        public proSmvService: ProSMVService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.proSubAnalysisColumn = proSubAnalysisColumns;
        this.proAnalysisHistoryColumns = proAnalysisHistoryColumns;
        this.getAuthData();
        this.getFactoryId();
        this.getProSMVCodeData();
        this.getStages();
    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
    }

    toggleLock(lock: boolean) {

        if (this.operationId && this.sizeGridId && this.sizeId) {
            this.spinner = true;
            this.proSmvService.lockUnlockValue(this.operationId, this.sizeGridId, this.sizeId, this.stageId, lock).subscribe((response: any) => {
                //  response.data[0];
                console.log(response);
                this.getOpData();
                this.spinner = false;
            }, error => {
                this.spinner = false;
            });
        }


    }


    /**
     * fetch role and permissions
     */
    getAuthData() {
        this.spinner = true;
        const auth = this.sharedService.authData$.subscribe((data: any) => {
            const authData = data.find((el) => {
                return el.name === 'ProSmv';
            });
            const proAuthData = authData.subModules.find((el) => {
                return el.subModuleName === 'ProSmv';
            });
            const subAuthData = authData.subModules.find((el) => {
                return el.subModuleName === 'Subsection';
            });
            this.proAuthData = proAuthData;
            this.spinner = false;
        });
    }

    createChart() {
        this.spinner = true;
        am4core.useTheme(am4themes_themes);
        am4core.useTheme(am4themes_animated);
        const proAnalysis = am4core.create('analysisChart', am4charts.RadarChart);

        // Add data
        proAnalysis.data = [{
            category: 'Approved',
            value: this.chartValues.approvedCount ? (this.chartValues.approvedCount * 100) /
                this.chartValues.totalOperation : 0,
            full: 100
        }, {
            category: 'Sent for Approval',
            value: this.chartValues.sendApprovalCount ? (this.chartValues.sendApprovalCount * 100) /
                this.chartValues.totalOperation : 0,
            full: 100
        }, {
            category: 'Saved',
            value: this.chartValues.savedCount ? (this.chartValues.savedCount * 100) /
                this.chartValues.totalOperation : 0,
            full: 100
        }, {
            category: 'Total',
            value: this.chartValues.totalOperation ? this.chartValues.totalOperation : 0,
            full: 100
        }];

        // Make chart not full circle
        proAnalysis.startAngle = -90;
        proAnalysis.endAngle = 180;
        proAnalysis.innerRadius = am4core.percent(20);

        // Set number format
        proAnalysis.numberFormatter.numberFormat = '#.#\'%\'';

        // Create axes
        const categoryAxis1 = proAnalysis.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
        categoryAxis1.dataFields.category = 'category';
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.grid.template.strokeOpacity = 0;
        categoryAxis1.renderer.labels.template.horizontalCenter = 'right';
        // categoryAxis.renderer.labels.template.fontWeight = 500;
        categoryAxis1.renderer.labels.template.adapter.add('fill', function (fill, target) {
            return (target.dataItem.index >= 0) ? proAnalysis.colors.getIndex(target.dataItem.index) : fill;
        });
        categoryAxis1.renderer.minGridDistance = 10;

        const proAnalysisvalueAxis1 = proAnalysis.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        proAnalysisvalueAxis1.renderer.grid.template.strokeOpacity = 0;
        proAnalysisvalueAxis1.min = 0;
        proAnalysisvalueAxis1.max = 100;
        proAnalysisvalueAxis1.strictMinMax = true;

        // Create series
        const proAnalysisseries1 = proAnalysis.series.push(new am4charts.RadarColumnSeries());
        proAnalysisseries1.dataFields.valueX = 'full';
        proAnalysisseries1.dataFields.categoryY = 'category';
        proAnalysisseries1.clustered = false;
        proAnalysisseries1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
        proAnalysisseries1.columns.template.fillOpacity = 0.08;
        // proAnalysisseries1.columns.template.cornerRadiusTopLeft = 20;
        proAnalysisseries1.columns.template.strokeWidth = 0;
        proAnalysisseries1.columns.template.radarColumn.cornerRadius = 20;

        const proAnalysisseries2 = proAnalysis.series.push(new am4charts.RadarColumnSeries());
        proAnalysisseries2.dataFields.valueX = 'value';
        proAnalysisseries2.dataFields.categoryY = 'category';
        proAnalysisseries2.clustered = false;
        proAnalysisseries2.columns.template.strokeWidth = 0;
        proAnalysisseries2.columns.template.tooltipText = '{category}: [bold]{value}[/]';
        proAnalysisseries2.columns.template.radarColumn.cornerRadius = 20;

        proAnalysisseries2.columns.template.adapter.add('fill', function (fill, target) {
            return proAnalysis.colors.getIndex(target.dataItem.index);
        });
        proAnalysis.cursor = new am4charts.RadarCursor();
        this.spinner = false;
    }

    /**
     * fetch factory id of logged in user
     */
    getFactoryId() {
        this.spinner = true;
        this.proSmvService.getFactoryId().subscribe((response: any) => {
            this.factoryId = response.data[0].id;
            this.factoryList = response.data;
            this.spinner = false;
            this.getChartValues();
            this.getSizeGrid();
        });
    }

    /**
     * get style and size grid for pre fill
     */
    getSizeGrid() {
        this.spinner = true;
        this.proSmvService.getSizeGridSizes(this.factoryId).subscribe((response: any) => {
            this.sizeGridId = response.data.gridSize.id;
            this.sizeGridValue = response.data.gridSize.gridValue;
            this.sizeId = response.data.size.id;
            this.sizeName = response.data.size.sizeValue;
            this.spinner = false;
        });
    }

    /**
     * get Stages
     */
    getStages() {
        this.spinner = true;
        this.proSmvService.getStages().subscribe((response: any) => {
            this.stageList = response.data;
            this.stageId = 5;
            this.spinner = false;
        });
    }

    /**
     * get chart details
     */
    getChartValues() {
        this.spinner = true;
        this.proSmvService.getChartData(this.factoryId).subscribe((response: any) => {
            this.chartValues = response.data;
            this.createChart();
            this.spinner = false;
        }, error => {
            this.spinner = false;
        });
    }

    /**
     * while searching for available operations
     * @param event string to be searched
     */
    searchOperation(event) {
        this.proSmvService.searchOperation(event.query, this.factoryId).subscribe((response: any) => {
            const operationList = [];
            for (const op of response.data) {
                // comp.name = comp.name;
                operationList.push(op.operationNumber);
            }
            this.operationOptions = operationList.length > 0 ? operationList : ['No Data Found'];
            this.availableOperationList = response.data;
        });
    }

    /**
     * capture the id of selected operations
     * @param event selected operation from options
     */
    captureOperationId(event: any) {
        this.rowIndexArray = [];
        for (const list of this.availableOperationList) {
            if (list.operationNumber === event) {
                this.operationId = list.id;
            }
        }
        this.getOpData();
    }

    /**
     * while searching for available operations
     * @param event string to be searched
     */
    searchSizeGrid(event) {
        this.sizeId = null;
        this.sizeName = '';
        this.proSmvService.searchSizGrid(event.query, this.factoryId).subscribe((response: any) => {
            const sizeGridList = [];
            for (const op of response.data) {
                // comp.name = comp.name;
                sizeGridList.push(op.gridvalue);
            }
            this.sizeGridOptions = sizeGridList.length > 0 ? sizeGridList : ['No Data Found'];
            this.availableSizeGridList = response.data;
        });
    }

    /**
     * capture the id of selected operations
     * @param event selected operation from options
     */
    captureSizeGridId(event: any) {
        for (const list of this.availableSizeGridList) {
            if (list.gridvalue === event) {
                this.sizeGridId = list.id;
            }
        }
    }

    onCopySize() {
        if (!(this.operationId && this.sizeGridId && this.stageId)) {
            this.toaster.error("Required parameters are missing.", "Error");
            return;
        }

        this.spinner = true;
        this.proSmvService.getSizes(this.operationId, this.sizeGridId, this.stageId).subscribe({
            next: (response: any) => {
                this.spinner = false;
                this.availableCopySizeList = response.data.availableSizeList || [];
                this.unavailableCopySizeList = response.data.unvailableSizeList || [];
                this.resetSelectedSizes();
                this.ngxSmartModalService.getModal('myModal1').open();
            },
            error: () => {
                this.spinner = false;
                this.toaster.error("Failed to retrieve size lists. Please try again.", "Error");
            },
        });
    }

    copySizeUpdate() {
        const { oldSizeId, newSizeId } = this.selectedSizes;

        if (!oldSizeId || !newSizeId) {
            this.toaster.error("Please select both 'From' and 'To' sizes.", "Error");
            return;
        }

        const payload = {
            operationId: this.operationId,
            sizeGridId: this.sizeGridId,
            oldSizeId,
            newSizeId,
            stageId: this.stageId,
        };

        this.spinner = true;
        this.proSmvService.copySizes(payload).subscribe({
            next: (response: any) => {
                this.spinner = false;
                if (response) {
                    this.toaster.success("Data copied successfully.", "Success");
                    this.ngxSmartModalService.getModal('myModal1').close();
                    this.getOpData();
                } else {
                    this.toaster.error("Failed to copy data. Please try again.", "Error");
                }
            },
            error: () => {
                this.spinner = false;
                this.toaster.error("An error occurred while copying data.", "Error");
            },
        });
    }

    private resetSelectedSizes() {
        this.selectedSizes = { oldSizeId: null, newSizeId: null };
    }



    /**
     * while searching for available operations
     * @param event string to be searched
     */
    searchSizes(event) {
        this.proSmvService.searchSize(event.query, this.sizeGridId).subscribe((response: any) => {
            const sizeList = [];
            for (const op of response.data) {
                // comp.name = comp.name;
                sizeList.push(op.sizeValue);
            }
            this.sizesOptions = sizeList.length > 0 ? sizeList : ['No Data Found'];
            this.availableSizeList = response.data;
        });
    }

    /**
     * capture the id of selected operations
     * @param event selected operation from options
     */
    captureSizesId(event: any) {
        for (const list of this.availableSizeList) {
            if (list.sizeValue === event) {
                this.sizeId = list.id;
            }
        }
        this.getOpData();
    }

    resetAnalysisData(type) {
        if (type === 'grid') {
            this.sizeGridId = null;
        } else {
            this.sizeId = null;
        }
        this.opAnalysis = {};
        this.proSubAnalysisData = [];
    }

    /**
     * API call to get Card Data with selected Operation
     */
    getOpData() {
        if (this.operationId && this.sizeGridId && this.sizeId) {
            this.spinner = true;
            this.proSmvService.getOpData(this.operationId, this.sizeGridId, this.sizeId, this.stageId).subscribe((response: any) => {
                this.opAnalysis = response.data[0];
                if (this.opAnalysis.analysisHeaderDTO) {
                    this.analysisHeaderId = this.opAnalysis.analysisHeaderDTO.id;
                    if (this.opAnalysis.analysisHeaderDTO.status === 'Rejected') {
                        this.color = 'disapprove';
                        this.value = 100;
                    } else if (this.opAnalysis.analysisHeaderDTO.status === 'Approved') {
                        this.color = 'approve';
                        this.value = 100;
                    } else if (this.opAnalysis.analysisHeaderDTO.status === 'Pending approval') {
                        this.color = 'send';
                        this.value = 75;
                    } else if (this.opAnalysis.analysisHeaderDTO.status === 'SAVE') {
                        this.color = 'save';
                    }
                }
                if (this.opAnalysis.operationAnalysisDTO) {
                    this.opAnalysis.operationAnalysisDTO.stichesPerCm = this.opAnalysis.operationAnalysisDTO.stichesPerCm.toFixed(1);
                } else {
                    const defaultValues = {
                        stichesPerCm: 4.5,
                        otherAllowance: 0,
                        bundleAllowance: 0.03,
                        contingencyAllowance: 0,
                        personalAllowance: 0,
                        totalTime: 0,
                        basicTime: 0,
                        stdTime: 0,
                        stdAllowedHours: 0,
                        target: 0,
                    };
                    this.opAnalysis['operationAnalysisDTO'] = defaultValues;
                    this.opAnalysis.operationAnalysisDTO.stichesPerCm = this.opAnalysis.operationAnalysisDTO.stichesPerCm.toFixed(1);
                }
                this.proSubAnalysisData = this.opAnalysis.operationSubAnalysisDTO;
                this.spinner = false;
            }, error => {
                this.spinner = false;
            });
        }

    }

    /**
     * get Pro SMV Code table Data
     */
    getProSMVCodeData() {
        this.spinner = true;
        this.proSmvService.getProSmvCode().subscribe((response: any) => {
            this.proSMVCodeData = response.data.basic;
            this.pickupData = response.data.pickUp;
            this.foldData = response.data.fold;
            this.lineUpData = response.data.lineUp;
            this.machineData = response.data.machine;
            this.mtmData = response.data.mtm;
            this.disposeData = response.data.dispose;
            this.cutData = response.data.cut;
            this.spinner = false;
        });
    }

    /**
     * add object in sub Analysis table
     * @param data selecte object
     */
    addSubAnalysis(data) {
        if (this.operationId && this.defaultFrequency) {
            this.spinner = true;
            // data.id = null;
            data.newData = true;
            data.frequency = this.defaultFrequency;
            data.tmuAllowed = data.tmu * data.frequency;
            data.extTmu = data.tmu * data.frequency;
            this.proSubAnalysisData.push(cloneDeep(data));
            const tTime = this.opAnalysis.operationAnalysisDTO.totalTime ? this.opAnalysis.operationAnalysisDTO.totalTime : 0;
            this.opAnalysis.operationAnalysisDTO.totalTime = tTime + data.tmu;
            this.getUpdatedOPAnalysis();
            this.spinner = false;
        } else {
            this.toaster.error('Please add default freequency', 'Error');
        }
    }

    /**
     * getUpdatedOPAnalysis
     */
    getUpdatedOPAnalysis() {
        this.spinner = true;
        let totalTMU = 0;
        for (const dat of this.proSubAnalysisData) {
            totalTMU = totalTMU + dat.tmuAllowed;
        }
        const data = {
            bundleAllowance: this.opAnalysis.operationAnalysisDTO.bundleAllowance,
            contingencyAllowance: this.opAnalysis.gradeDTO.contingencyAllowance,
            otherAllowance: this.opAnalysis.operationAnalysisDTO.otherAllowance,
            // sewData: "string",
            personalAllowance: this.opAnalysis.gradeDTO.personalAllowance,
            stichesPerCm: this.opAnalysis.operationAnalysisDTO.stichesPerCm,
            totalTmu: totalTMU,
        };
        this.proSmvService.updateAnalysis(data).subscribe(
            result => {
                this.opAnalysis.operationAnalysisDTO.basicTime = result.data.basicTime;
                this.opAnalysis.operationAnalysisDTO.stdTime = result.data.standardTime;
                this.opAnalysis.operationAnalysisDTO.target = result.data.target;
                this.opAnalysis.operationAnalysisDTO.totalTime = result.data.totalTmu;
                if (this.proSubAnalysisData.length === 0) {
                    this.manipulateAnlysis();
                } else {
                    this.spinner = false;
                }
            },
            error => {
                this.spinner = false;
            }
        );
    }

    /**
     * manipulate create analysis data
     */
    manipulateAnlysis() {
        this.spinner = true;
        this.proSubAnalysisData.forEach(element => {
            if (element.newData) {
                element.id = null;
                delete element['newData'];
            }
        });
        this.createAnalysis.smvCodeForm = this.proSubAnalysisData;
        this.createAnalysis.analystName = localStorage.getItem('username');
        this.createAnalysis.basicTime = this.opAnalysis.operationAnalysisDTO ? this.opAnalysis.operationAnalysisDTO.basicTime : 0;
        this.createAnalysis.bundleAllowance = this.opAnalysis.operationAnalysisDTO ?
            +this.opAnalysis.operationAnalysisDTO.bundleAllowance : 0.01;
        this.createAnalysis.contingencyAllowance = this.opAnalysis.gradeDTO ?
            this.opAnalysis.gradeDTO.contingencyAllowance : 0;
        this.createAnalysis.otherAllowance = this.opAnalysis.operationAnalysisDTO ? this.opAnalysis.operationAnalysisDTO.otherAllowance : 0;
        this.createAnalysis.personalAllowance = this.opAnalysis.gradeDTO ?
            this.opAnalysis.gradeDTO.personalAllowance : 0;
        this.createAnalysis.stdAllowedHr = this.opAnalysis.operationAnalysisDTO ?
            this.opAnalysis.operationAnalysisDTO.stdAllowedHours : 0;
        this.createAnalysis.stdMin = this.opAnalysis.stdMin ? this.opAnalysis.stdMin : 0;
        this.createAnalysis.stdTime = this.opAnalysis.operationAnalysisDTO ? this.opAnalysis.operationAnalysisDTO.stdTime : 0;
        this.createAnalysis.stichesPerCm = this.opAnalysis.operationAnalysisDTO ?
            +this.opAnalysis.operationAnalysisDTO.stichesPerCm : 2.0;
        this.createAnalysis.target = this.opAnalysis.operationAnalysisDTO ?
            this.opAnalysis.operationAnalysisDTO.target : 0;
        this.createAnalysis.totalTime = this.opAnalysis.operationAnalysisDTO ?
            this.opAnalysis.operationAnalysisDTO.totalTime : 0;
        this.createAnalysis.gridId = this.sizeGridId;
        this.createAnalysis.sizeId = this.sizeId;
        this.createAnalysis.operationId = this.operationId;
        this.createAnalysis.operationAnalysisId = this.opAnalysis.operationAnalysisDTO ?
            this.opAnalysis.operationAnalysisDTO.id : null;
        this.createAnalysis.stageId = this.stageId;
        this.spinner = false;
        this.saveAnalysis();
    }

    /**
     * save analysis
     */
    saveAnalysis() {
        this.spinner = true;
        this.proSmvService.createAnalysis(this.createAnalysis).subscribe(
            result => {
                this.spinner = false;
                this.color = 'save';
                this.value = 50;
                this.toaster.success(result.message, 'Success');
                this.getOpData();
            },
            error => {
                this.spinner = false;
            }
        );
    }

    /**
     * send for approval
     */
    sendForApproval() {
        this.spinner = true;
        this.proSmvService.SendApproval(this.analysisHeaderId, this.operationId, this.sizeGridId,
            this.sizeId, this.opAnalysis.operationAnalysisDTO.id, this.stageId).subscribe(
                result => {
                    this.spinner = false;
                    this.color = 'send';
                    this.value = 75;
                    this.toaster.success(result.message, 'Success');
                    this.getOpData();
                },
                error => {
                    this.spinner = false;
                }
            );
    }

    /**
     * send for disapproval
     */
    disapproveOperation() {
        this.spinner = true;
        this.proSmvService.disapproveOperation(this.analysisHeaderId, this.operationId,
            this.sizeGridId, this.sizeId, this.opAnalysis.operationAnalysisDTO.id, this.stageId).subscribe(
                result => {
                    this.spinner = false;
                    this.color = 'disapprove';
                    this.value = 100;
                    this.toaster.success(result.message, 'Success');
                    this.getOpData();
                },
                error => {
                    this.spinner = false;
                }
            );
    }

    /**
     * send for approved
     */
    operationApprove() {
        this.proSmvService.approveOperation(this.analysisHeaderId, this.operationId,
            this.sizeGridId, this.sizeId, this.opAnalysis.operationAnalysisDTO.id, this.stageId).subscribe(
                result => {
                    this.spinner = false;
                    this.color = 'approve';
                    this.value = 100;
                    this.toaster.success(result.message, 'Success');
                    this.getOpData();
                },
                error => {
                    this.spinner = false;
                }
            );
    }

    /**
     * Update Ext Tmu
     */
    updateExtTMU(rowData, index) {
        this.spinner = true;
        const updatedTMU = Math.round(rowData.frequency * rowData.tmu);
        this.opAnalysis.operationAnalysisDTO.totalTmu = updatedTMU;
        this.proSubAnalysisData[index].extTmu = (updatedTMU);
        if (rowData.allowance) {
            this.proSubAnalysisData[index].tmuAllowed = Math.round((updatedTMU * rowData.allowance / 100) + updatedTMU);
        } else {
            this.proSubAnalysisData[index].tmuAllowed = updatedTMU;
        }
        this.spinner = false;
        // setTimeout(() => {
        this.getUpdatedOPAnalysis();
        // }, 5000);
    }

    /**
     * To open create category Modal
     */
    openCalculatorModal(): void {
        this.display = true;
        this.calValue = '';
    }

    /**
     * updating calc label or digits
     */
    updateCalcNumber(val) {
        this.calValue = `${this.calValue}${val}`;
    }

    /**
     * clear calc value
     */
    clearCalcNumber() {
        if (this.calValue != null && this.calValue !== '') {
            this.calValue = this.calValue.substring(0, this.calValue.length - 1);
        }
    }

    /**
     * validate cal Value
     */
    validateNumber() {
        if (this.calValue.includes('S') || this.calValue.includes('E') || this.calValue.includes('M') ||
            this.calValue.includes('T') || this.calValue.includes('D')) {
            if (this.calValue.includes('X') || this.calValue.includes('Y') || this.calValue.includes('Z') ||
                this.calValue.includes('Ce') || this.calValue.includes('C')) {
                this.sewCalculation();
            }
        }
    }

    /**
     * SEW calculation
     */
    sewCalculation() {
        this.spinner = true;
        let totalTMU = 0;
        for (const dat of this.proSubAnalysisData) {
            totalTMU = totalTMU + dat.tmuAllowed;
        }
        const data = {
            allowance: this.opAnalysis.machineDTO.allowance,
            machineRPM: this.opAnalysis.machineDTO.rpm,
            bundleAllowance: this.opAnalysis.operationAnalysisDTO.bundleAllowance,
            contingencyAllowance: this.opAnalysis.gradeDTO.contingencyAllowance,
            otherAllowance: this.opAnalysis.operationAnalysisDTO.otherAllowance,
            sewData: this.calValue,
            personalAllowance: this.opAnalysis.gradeDTO.personalAllowance,
            stichesPerCm: this.opAnalysis.operationAnalysisDTO.stichesPerCm,
            totalTmu: totalTMU,
            frequency: this.defaultFrequency,
        };
        this.proSmvService.sewCalculation(data).subscribe(
            result => {
                this.opAnalysis.operationAnalysisDTO.basicTime = result.data.basicTime;
                this.opAnalysis.operationAnalysisDTO.stdTime = result.data.standardTime;
                this.opAnalysis.operationAnalysisDTO.target = result.data.target;
                this.opAnalysis.operationAnalysisDTO.totalTmu = result.data.totalTmu;
                this.opAnalysis.operationAnalysisDTO.totalTime = result.data.totalTmu;
                this.opAnalysis.operationSubAnalysisDTO.push(result.data.operationSubAnalysisDTO);
                this.spinner = false;
                this.restetCalc();
            },
            error => {
                this.spinner = false;
            }
        );
    }

    /**
     * PRE/PRM Calculation
     */
    preCalculation(typeOfCalculation) {
        let totalTMU = 0;
        for (const dat of this.proSubAnalysisData) {
            totalTMU = totalTMU + dat.tmuAllowed;
        }
        if (/^[\d]{0,}$/.test(this.calValue)) {
            this.spinner = true;
            const data = {
                allowance: this.opAnalysis.machineDTO.allowance,
                bundleAllowance: this.opAnalysis.operationAnalysisDTO.bundleAllowance,
                contingencyAllowance: this.opAnalysis.gradeDTO.contingencyAllowance,
                otherAllowance: this.opAnalysis.operationAnalysisDTO.otherAllowance,
                prePrmData: this.calValue,
                personalAllowance: this.opAnalysis.gradeDTO.personalAllowance,
                stichesPerCm: this.opAnalysis.operationAnalysisDTO.stichesPerCm,
                totalTmu: totalTMU,
                type: typeOfCalculation,
                frequency: this.defaultFrequency
            };
            this.proSmvService.preprmCalculation(data).subscribe(
                result => {
                    this.opAnalysis.operationAnalysisDTO.basicTime = result.data.basicTime;
                    this.opAnalysis.operationAnalysisDTO.stdTime = result.data.standardTime;
                    this.opAnalysis.operationAnalysisDTO.target = result.data.target;
                    this.opAnalysis.operationAnalysisDTO.totalTmu = result.data.totalTmu;
                    this.opAnalysis.operationAnalysisDTO.totalTime = result.data.totalTmu;
                    this.opAnalysis.operationSubAnalysisDTO.push(result.data.operationSubAnalysisDTO);
                    this.spinner = false;
                    this.restetCalc();
                },
                error => {
                    this.spinner = false;
                }
            );
        } else {
        }
    }

    /**
     * reset calc
     */
    restetCalc() {
        this.calValue = '';
    }

    /**
     * showVideo
     */
    showVideo(videoLink) {
        this.showVideos = true;
        this.selectedVideoLink = videoLink;
    }

    updateDesc(desc, id) {
        if (id) {
            this.spinner = true;
            this.proSmvService.updateDesc(desc, id).subscribe((response: any) => {
                this.spinner = false;
                this.toaster.success(response.message, 'Success');
            });
        }
    }

    selectItems(event, item) {
        if (this.proSubAnalysisData.length !== 0) {
            if (item === 'all') {
                this.selectAll = event.target.checked;
                this.proSubAnalysisData.forEach((el) => el.itemChecked = event.target.checked);
                this.selectedItems = event.target.checked ? this.proSubAnalysisData : [];
                if (event.target.checked) {
                    this.rowIndexArray = [];
                    for (let i = 0; i < this.proSubAnalysisData.length; i++) {
                        this.rowIndexArray.push(i);
                    }
                }
                else {
                    this.rowIndexArray = [];
                }
            }
            else {
                this.proSubAnalysisData.forEach((el) => {
                    if (el.id === item.id) {
                        el.itemChecked = event.target.checked;
                        let rowData = event.target.closest("tr").rowIndex;
                        if (this.rowIndexArray.includes(rowData)) {
                            let index = this.rowIndexArray.indexOf(rowData);
                            this.rowIndexArray.splice(index, 1);
                        }
                        else {
                            this.rowIndexArray.push(rowData);
                        }
                    }
                });
                this.selectedItems = this.proSubAnalysisData.filter((el) => el.itemChecked);
                this.selectAll = (this.selectedItems.length === this.proSubAnalysisData.length);
            }
        }
        else {
            event.target.checked = false;
        }
    }

    /**
     * delete code
     */
    deleteCode() {
        this.translate.get(['DELETE_CONFIRM_TITLE', 'DELETE_CONFIRM_TEXT', 'DELETE_CONFIRM_BUTTON', 'DELETE_CANCEL_BUTTON', 'DELETE_SUCCESS', 'FILE_DELETED']).subscribe(translations => {
            Swal.fire({
                title: translations['DELETE_CONFIRM_TITLE'],
                text: translations['DELETE_CONFIRM_TEXT'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: translations['DELETE_CONFIRM_BUTTON'],
                cancelButtonText: translations['DELETE_CANCEL_BUTTON']
            }).then(result => {
                if (result.value === true) {

                    // for(let i=0; i < this.rowIndexArray.length; i++){
                    //   this.proSubAnalysisData.splice(this.rowIndexArray[i], 1);
                    // }
                    let deletedItems = [];
                    for (let i = 0; i < this.proSubAnalysisData.length; i++) {
                        for (let j = 0; j < this.rowIndexArray.length; j++) {
                            if (i == this.rowIndexArray[j]) {
                                deletedItems.push(this.proSubAnalysisData[i]);
                            }
                        }
                    }
                    let filteredData = [];
                    this.proSubAnalysisData.forEach(item => {
                        if (!deletedItems.includes(item)) {
                            filteredData.push(item);
                        }
                    });
                    this.proSubAnalysisData = filteredData;
                    this.getUpdatedOPAnalysis();
                    this.rowIndexArray = [];
                    Swal.fire(translations['DELETE_SUCCESS'], translations['FILE_DELETED'], 'success');
                }
            });
        })
    }
    /**
     * image and video popup
     */
    imageVideoModal(type) {
        this.viewType = type === 'BEFORE' ? 'Before Video' : (type === 'AFTER' ? 'After Video' : 'Image');
        this.documents = [];
        if (this.opAnalysis.documents.length > 0) {
            for (const data of this.opAnalysis.documents) {
                if (data.documentType === type) {
                    this.documents.push(data);
                } else {
                    if (data.usedType === type) {
                        this.documents.push(data);
                    }
                }
            }
            this.ngxSmartModalService.getModal('imageVideoPopup').open();
        }
    }

    /**
     * while searching for available operations
     * @param event string to be searched
     */
    searchAnalysisOperation(event) {
        this.proSmvService.searchOperation(event.query, this.factoryId).subscribe((response: any) => {
            const operationList = [];
            for (const op of response.data) {
                // comp.name = comp.name;
                operationList.push(op.operationNumber);
            }
            this.operationAnalysisOptions = operationList.length > 0 ? operationList : ['No Data Found'];
            this.availableOperationList = response.data;
        });
    }

    /**
     * capture the id of selected operations
     * @param event selected operation from options
     */
    captureAnalysisOperationId(event: any) {
        for (const list of this.availableOperationList) {
            if (list.operationNumber === event) {
                this.analysisOperationId = list.id;
            }
        }
        this.getAnalysisHistory();
    }

    /**
     * analysis history model
     */
    openHistoryAnalysis() {
        this.analysisFactoryId = this.factoryId;
        this.analysisOperationId = this.operationId;
        this.analysisOperation = this.operationName;
        this.getAnalysisHistory();
        this.ngxSmartModalService.getModal('analysisHistory').open();
    }

    /**
     * get analysis history
     */
    getAnalysisHistory() {
        this.spinner = true;
        this.proSmvService.getAnalysisHistory(this.analysisOperationId).subscribe((response: any) => {
            this.opAnalysisHistory = response.data;
            this.spinner = false;
        });
    }

    /**
     * print Analysis
     */
    printAnalysis() {
        this.spinner = true;
        this.proSmvService.printAnalysisData(this.factoryId, this.operationId, this.sizeGridId,
            this.sizeId, this.stageId).subscribe((response: any) => {
                this.spinner = false;
                if (response.data) {
                    this.downloadFile(response.data);
                }
            }, (error) => {
                this.spinner = false;
            });
    }

    downloadFile(fileName: string) {
        this.proSmvService.downloadFile(fileName).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/octet-stream' });
            const downloadURL = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'Analysis.pdf';
            link.click();
            this.spinner = false;
        }, (error) => {
            this.spinner = false;
        });
    }
}
