import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SequenceService } from './sequence.service';
import { ToastrService } from 'ngx-toastr';
import { sequenceTableCol, sequenceTableAllCol, versionCols } from './sequence-list';
import { SlideInOutAnimation } from '../animations/animation';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.sass'],
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    SlideInOutAnimation
  ],
})
export class SequenceComponent implements OnInit {
  searchText: any;
  optionSelected: string;
  flip: any;
  factoryId: any;
  resultsStyle: Array<any> = [];
  styleList: any;
  createSequence: any = {};
  factoryIdDTO: any;
  sequence: Array<any> = [];
  historyList: Array<any> = [];
  versionList: Array<any> = [];
  version: number;
  versions: number;
  copyList: any;
  styleId: any;
  toStyleName: string;
  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];
  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];
  toStyleList: any[];
  tostyleData: any;
  tostyleId: number;
  styleForm = false;
  searchDescOper = false;
  descriptionList: any[];
  descList: any;
  descId: any;
  opId: any;
  operationsList: any[];
  OpList: any;
  sequenceList: any;
  cols: any;
  vCols: Array<any> = [];
  selectedCars3: any[] = [];
  toSequence: any;
  searchOPDescTable: any;
  operationSearchList: any[];
  progressPoints: Array<any> = [];
  brands: Array<any> = [];
  animationState = 'out';
  spinner: boolean = true;
  stageList: Array<any> = [];
  stageId: number;
  plannedStyleData: any = {};
  importData: any = {};
  importStyle: any[];
  opSeqAuthData: any = {};
  subAuthData: any = {};
  totalStandardTime: number;
  importFile: any;
  isSearchBy = '';
  styleStatus = '';
  subsectionListOptions: Array<any> = [];
  subsectionList: Array<any> = [];
  searchedSequenceDebounce = _.debounce(this.searchOperation, 700);
  searchedSequenceDescriptionDebounce = _.debounce(this.searchDescription, 700);

  selectAll : any;
  selectedItems: any[];
  changeSequence = false;

    FactorySettingsUpdate: any = [];
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private toastr: ToastrService,
    private service: SequenceService,
    public activatedRoute: ActivatedRoute,
    public httpClient: HttpClient,
    public sharedService: SharedService
  ) {
    this.createSequence.factoryId = this.factoryId;
    this.createSequence.styleId = this.styleId;
    this.styleId = null;
    this.tostyleId = null;
    this.cols = sequenceTableCol;
    this.vCols = versionCols;
  }

  ngOnInit() { 
    this.getFactoryId();
    this.getStages();
    this.getAuthData();
    const style = JSON.parse(localStorage.getItem('selectedStyle'));
    if (style) {
      this.createSequence.styleName = style.name;
      this.styleId = style.id;
    }
    this.progressPoints = [
      { label: 'Choose', value: '', disableVal: '0' },
      { label: 'Online', value: 'Online', disableVal: '0' },
      { label: 'Offline', value: 'Offline', disableVal: '0' },
    ];
    // localStorage.setItem('lastPage', this.activatedRoute.url['_value'][0].path);
  }

  /**
   * fetch role and permissions
   */
  getAuthData() {
    // this.spinner = true;
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'Operation';
      });
      const opSeqAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Operation Sequence';
      });
      const subAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Subsection';
      });
      this.opSeqAuthData = opSeqAuthData;
      this.subAuthData = subAuthData;
      // this.spinner = false;
    });
  }

  /**
   *
   */
  redirecToThread() {
    localStorage.setItem('lastPage', this.activatedRoute.url['_value'][0].path);
    this.router.navigate(['threadConsumption']);
  }

  /**
   * show or hide input field
   */
  showHideFields(checked) {
    if (checked) {
      this.searchDescOper = true;
    } else {
      this.searchDescOper = false;
    }
  }

  getSequence() {
    this.spinner = true;
    this.service.getSequence(this.styleId, this.stageId).subscribe((response: any) => {
      this.sequence = response.data;
      let totalStdTime = 0;
      let stdTime;
      for (const iterator of this.sequence) {
        if (iterator.progressPoint) {
          iterator.progressPointName = iterator.progressPoint.name ? iterator.progressPoint.name : '';
        }
        if (iterator.group) {
          iterator.groupName = iterator.group.name ? iterator.group.name : '';
        }
        stdTime = iterator.stdTime ? iterator.stdTime : iterator.operation ? iterator.operation.stdMin : 0;
        totalStdTime = totalStdTime + stdTime;
      }
      this.totalStandardTime = totalStdTime;
      this.spinner = false;
    }, (error) => {
      this.spinner = false;
    });
  }

  /**
   * get sequence list while copy style
   */
  getToStyleSequences() {
    this.spinner = true;
    this.service.getSequence(this.tostyleId, this.stageId).subscribe((response: any) => {
      if (response.data) {
        this.toSequence = response.data;
        this.spinner = false;
      }
    }, (error) => {
      this.spinner = false;
    });
  }

  // approveStyle() {
  //   this.service.approveSequence(this.styleId).subscribe((response: any) => {
  //     this.toastr.success(response.message, 'Success');
  //   },
  //   (error) => {
  //     this.toastr.error(error.error.message, 'Error');
  //   });
  // }

  getHistorySequence() {
    this.spinner = true;
    this.service.getHistorySequence(this.styleId, this.stageId).subscribe((response: any) => {
      if (response.data) {
        this.historyList = response.data;
        this.ngxSmartModalService.getModal('historyModal').open();
        this.spinner = false;
      }
    }, (error) => {
      this.spinner = false;
    }
    );
  }

  getFactoryId() {
    // this.spinner = true;
    this.service.getFactoryId().subscribe((response: any) => {
      if (response.data) {
        this.factoryIdDTO = response.data;
        this.createSequence.factoryId = this.factoryIdDTO[0].id;
        this.getFactorySettings(this.createSequence.factoryId);
      }
      // this.spinner = false;
    });
  }

  /**
   * get Stages
   */
  getStages() {
    // this.spinner = true;
    this.service.getStages().subscribe((response: any) => {
      this.stageList = response.data;
      this.stageId = this.stageList[0].id;
      if (this.styleId) {
         console.log("capturing stages")
        this.getSequence();
        this.getPlanningStyle();
        this.getApprovedStatus();
      } else {
        this.spinner = false;
      }
    });
  }

  getFactorySettings(factoryId:any){
    this.service.getFactorySettings(factoryId).subscribe({
    next: (response: any) => {
     this.FactorySettingsUpdate = response.data.settingDTOS.reduce((acc: any, item: any) => {
        acc[item.key] = item.value; 
        return acc;
      }, {});
      console.log(this.FactorySettingsUpdate)
    },
    error: (error) => {
      console.error('Error fetching factory settings:', error);
      this.toastr.error(error.error.message, 'Error');
    },
  });
}
  searchStyle(event) {
    this.styleId = null;
    this.service.searchStyles(event.query, this.createSequence.factoryId).subscribe((response: any) => {
      const styleList = [];
      for (const style of response.data) {
        style.name = style.name;
        styleList.push(style.name);
      }
      this.resultsStyle = styleList.length > 0 ? styleList : ['No Styles Found'];
      this.styleList = response.data;
    });
  }

  captureStyle(event: any) {
    for (const list of this.styleList) {
      if (list.name === event) {
        this.styleId = list.id;
        localStorage.setItem('selectedStyle', JSON.stringify(list));
      }
    }
    console.log("capturing styles")
    this.getSequence();
    this.getPlanningStyle();
    this.getApprovedStatus();
  }

  /**
   * search to style list
   * @param event string to be search
   */
  toSearchStyle(event) {
    this.service.searchStyles(event.query, this.createSequence.factoryId).subscribe((response: any) => {
      const tostyleLists = [];
      for (const style of response.data) {
        style.name = style.name;
        tostyleLists.push(style.name);
      }
      // if (this.createSequence.styleName) {
      //   const index: number = tostyleLists.indexOf(this.createSequence.styleName);
      //   tostyleLists.splice(index, 1);
      // }
      this.toStyleList = tostyleLists.length > 0 ? tostyleLists : ['No Styles Found'];
      this.tostyleData = response.data;
    });
  }

  /**
   * select to style id
   * @param event
   */
  capturetoStyleId(event) {
    for (const styleData of this.tostyleData) {
      if (styleData.name === event) {
        this.tostyleId = styleData.id;
      }
    }
    this.getToStyleSequences();
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  /**
   * saving Operations Sequence List
   */
  saveOperationsList() {
    this.spinner = true;
    this.createDateToSend(this.sequence);
    this.service.updateSequenceList(this.styleId, this.sequence, this.stageId).subscribe((response: any) => {
      this.toastr.success(response.message, 'Success');
      this.getSequence();
      this.spinner = false;
    }, (error) => {
      this.spinner = false;
      this.toastr.error(error.error.message, 'Error');
    });
  }

  /**
   * open toStyle modal
   */
  public openCopyStyleModal() {
    this.ngxSmartModalService.getModal('copyOperationModal').open();
  }

  /**
   * send style for approval
   */
  sendForApproval() {
    this.spinner = true;
    this.service.sendForApproval(this.styleId, this.stageId).subscribe((response: any) => {
      this.getApprovedStatus();
      this.toastr.success(response.message, 'Success');
    },
      (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Error');
      });
  }

  /**
   * approve style
   */
  approveStyle() {
    this.spinner = true;
    this.service.approveSequence(this.styleId, this.stageId).subscribe((response: any) => {
      this.getApprovedStatus();
      this.toastr.success(response.message, 'Success');
    },
      (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Error');
      });
  }

  /**
   * approve style
   */
  disApproveStyle() {
    this.spinner = true;
    this.service.disApproveSequence(this.styleId, this.stageId).subscribe((response: any) => {
      this.getApprovedStatus();
      this.toastr.success(response.message, 'Success');
    },
      (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Error');
      });
  }

  getVersionSequence() {
    this.spinner = true;
    this.service.getVersionsSequence(this.styleId, this.stageId).subscribe((response: any) => {
      if (response.data) {
        this.versionList = response.data;
        for (const vlist of this.versionList) {
          vlist.timeOfCreation = new Date(vlist.timeOfCreation);
        }
        this.ngxSmartModalService.getModal('versionModal').open();
      }
      this.spinner = false;
    },
    );
  }

  getVersion(version) {
    this.spinner = true;
    this.service.getVersions(this.styleId, version, this.stageId).subscribe((response: any) => {
      if (response.data) {
        this.versions = response.data;
        this.ngxSmartModalService.getModal('versionkeyModal').open();
      }
      this.spinner = false;
    },
    );
  }

  /**
   * search description using autocomplete
   * @param value string used to get search from backend
   */
  searchDescription(value) {
    this.descId = null;
    if (value) {
       let filterDescriptionData = "";
        if((value).includes("&")){
          filterDescriptionData = (value).replaceAll('&','%26').replaceAll("amp;","");
        }
        else{
          filterDescriptionData = value;
        }
      this.service.searchDesc(filterDescriptionData, this.createSequence.factoryId).subscribe((response: any) => {
        const descriptionLists = [];
        this.isSearchBy = 'Description';
        for (const desc of response.data) {
          desc.name = desc.name;
          descriptionLists.push(desc.description);
        }
        this.descriptionList = descriptionLists.length > 0 ? descriptionLists : ['No Styles Found'];
        this.descList = response.data;
        this.operationSearchList = this.descList;
      });
    } else {
      this.isSearchBy = '';
      this.operationSearchList = [];
    }
  }

  /**
   * capture Description selected
   */
  captureDescription(event) {
    // tslint:disable-next-line:prefer-for-of
    for (const descData of this.descList) {
      if (descData.name === event) {
        this.descId = descData.id;
      }
    }
  }

  /**
   * search description using autocomplete
   * @param event string used to get search from backend
   */
  searchOperation(value) {
    this.opId = null;
    if (value) {
      this.service.searchOperation(value, this.createSequence.factoryId).subscribe((response: any) => {
        const operationLists = [];
        this.isSearchBy = 'Operation';
        for (const operation of response.data) {
          operation.name = operation.name;
          operationLists.push(operation.operation_number);
        }
        this.operationsList = operationLists.length > 0 ? operationLists : ['No Styles Found'];
        this.OpList = response.data;
        this.operationSearchList = this.OpList;
      });
    } else {
      this.isSearchBy = '';
      this.operationSearchList = [];
    }
  }

  /**
   * capture operation selected
   */
  captureOperation(event) {
    this.operationSearchList = [];
    for (const descData of this.OpList) {
      if (descData.operation_number === event) {
        this.opId = descData.id;
        this.searchOPDescTable = descData;
        this.operationSearchList.push(descData);
      } else {
        this.opId = null;
      }
    }
  }

  /**
   * show all sequence of selected style
   */
  showAllSequence() {
    this.sequenceList = this.sequence;
    this.cols = sequenceTableAllCol;
    this.ngxSmartModalService.getModal('sequencesModal').open();
  }

  /**
   * open to style pop up
   */
  openTostyleModal() {
    this.ngxSmartModalService.getModal('toStyleModal').open();
  }

  /**
   * submit Copy style sequence data
   */
  copySequenceSubmit() {

  }

  oncopyStylesOperations() {

  }


  /**
   * add in main sequence table
   */
  addSequence(list) {
    list.seqNo = this.sequence.length + 1;
    this.sequence.push(list);
    this.addOperationSequence();
  }

  /**
   * get planned style data
   */
  getPlanningStyle() {
    // this.spinner = true;
    this.service.getPlannedStyle(this.styleId, this.stageId).subscribe((response: any) => {
      this.plannedStyleData = response.data;
      // this.spinner = false;
    });
  }

  /**
   * save planned style data
   */
  updatePlanningStyle() {
    if (this.plannedStyleData.itemNo && this.plannedStyleData.fabricType) {
      this.spinner = true;
      if (!this.plannedStyleData.hourPerDay ||
        !this.plannedStyleData.planManPower ||
        !this.plannedStyleData.planEfficiency) {
        this.plannedStyleData.theroaticalOP = '';
      } else {
        const theroaticalOP = (((this.plannedStyleData.hourPerDay / this.totalStandardTime) *
          this.plannedStyleData.planManPower) * this.plannedStyleData.planEfficiency) / 100;
        // this.plannedStyleData.theroaticalOP = theroaticalOP.toFixed(2);
        this.plannedStyleData.theroaticalOP = (isFinite(theroaticalOP) ? theroaticalOP.toFixed(2) : 0);
      }
      this.plannedStyleData['styleId'] = this.styleId;
      this.plannedStyleData['stageId'] = this.stageId;
      this.service.updatePlanningStyle(this.plannedStyleData).subscribe((response: any) => {
        this.plannedStyleData = response.data;
        this.spinner = false;
      });
    }
  }

  /**
   * open import popup
   */
  openImportPopup() {
    this.importData.factoryId = this.createSequence.factoryId;
    this.importData.styleId = this.styleId;
    this.importData.stageId = this.stageId;
    this.importData.styleName = this.createSequence.styleName;
    this.ngxSmartModalService.getModal('importModal').open();
  }

  /**
   * select import file
   */
  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.spinner = true;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('uploadedFile', file);
      this.service.importFile(formData, this.importData).subscribe((response: any) => {
        this.importFile = null;
        this.spinner = false;
        this.toastr.success(response.message, 'Success');
        this.getSequence();
      }, (error) => {
        this.spinner = false;
      });
    }
  }

  searchImportStyle(event) {
    this.importData.styleId = null;
    this.service.searchStyles(event.query, this.createSequence.factoryId).subscribe((response: any) => {
      const styleList = [];
      for (const style of response.data) {
        style.name = style.name;
        styleList.push(style.name);
      }
      this.importStyle = styleList.length > 0 ? styleList : ['No Styles Found'];
      this.styleList = response.data;
    });
  }

  captureImportStyle(event: any) {
    for (const list of this.styleList) {
      if (list.name === event) {
        this.importData.styleId = list.id;
      }
    }
  }

  selectItems(event, item) {
    if(this.sequence.length !== 0){
      if (item === 'all') {
        this.selectAll = event.target.checked;
        this.sequence.forEach((el) => el.itemChecked = event.target.checked);
        this.selectedItems = event.target.checked ? this.sequence : [];
      }
      else {
        this.sequence.forEach((el) => {
          if (el.id === item.id) {
            el.itemChecked = event.target.checked;
          }
        });
        this.selectedItems = this.sequence.filter((el) => el.itemChecked);
        this.selectAll = (this.selectedItems.length === this.sequence.length);
      }
    }
    else{
      event.target.checked = false;
    }
  }
  
//   deleteOperation() {
//     if(this.selectedItems !== undefined){
//     Swal.fire({
//       title: 'Are you sure?',
//       text: 'You wont be able to revert this!',
//       type: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!'
//     }).then(result => {
//       if (result.value === true) {
//         this.spinner = true;
//         this.selectedItems.forEach(data => {
//           const index: number = this.sequence.indexOf(data);
//           this.sequence.splice(index, 1);
//         });
        
//         this.createDateToSend(this.sequence);
//         this.service.deleteSequence(this.styleId, this.stageId, this.sequence).subscribe((response: any) => {
//           this.spinner = false;
//           Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
//           this.toastr.success(response.message, 'Success');
//           this.getSequence();
//         }, (error) => {
//           this.spinner = false;
//         });
//       }
//     });
//   }
//   }

  deleteOperation() {
    if (this.selectedItems !== undefined && this.selectedItems.length > 0) {
        Swal.fire({
                  title: 'Are you sure?',
                  text: 'You wont be able to revert this!',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then(result => {
        if (result.value=== true) {
          this.spinner = true;
  
          const isAllDeleted = this.selectedItems.length === this.sequence.length;
          let payload: any = [];
  
          if (isAllDeleted) {
            console.log(isAllDeleted,'all');
            this.sequence = [];
          } else {
            this.selectedItems.forEach(data => {
              const index: number = this.sequence.indexOf(data);
              if (index !== -1) {
                this.sequence.splice(index, 1);
              }
            });
            payload =this.sequence; 
          }
          this.createDateToSend(this.sequence);
          this.service.deleteSequence(this.styleId, this.stageId,isAllDeleted, payload).subscribe(
            (response: any) => {
              this.spinner = false;
              Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              this.toastr.success(response.message, 'Success');
              this.getSequence();
            },
            (error) => {
              this.spinner = false;
            }
          );
        }
      });
    }
  }
  

  createDateToSend(data) {
    for (const item of this.sequence) {
      if (item.operation) {
        item.operationId = item.operation.operationId;
      } else if (item.opid) {
        item.operationId = item.opid;
      }
    }
  }

  onChangeProgress(ev, index) {
    this.sequence[index].progressPointName = ' ';
    let exists = false;
    if (ev.value !== 'Choose' && ev.value !== '') {
      for (const data of this.sequence) {
        if (data.progressPointName === ev.value || (data.progressPoint &&
          data.progressPoint.name === ev.value)) {
          exists = true;
          this.toastr.error('already selected', 'Failed');
          this.sequence[index].progressPointName = ' ';
          break;
        }
      }
      if (!exists) {
        this.sequence[index].progressPointName = ev.value;
        this.updateOpSequence(this.sequence[index]);
      }
    } else {
      this.updateOpSequence(this.sequence[index]);
    }
  }

  updateOpSequence(rowData) {
    this.spinner = true;
    // rowData.groupName = rowData.groupName ? rowData.groupName : rowData.group ? rowData.group.name : '';
    if (rowData.groupName) {
      rowData.groupName = rowData.groupName;
    } else if (rowData.group) {
      rowData.groupName = rowData.group.name;
    } else {
      rowData.groupName = '';
    }
    rowData.remark = rowData.remark ? rowData.remark : '';
    if (rowData.progressPointName) {
      rowData.progressPointName = rowData.progressPointName;
    } else if (rowData.progressPoint) {
      rowData.progressPointName = rowData.progressPoint.name;
    } else {
      rowData.progressPointName = '';
    }
    this.service.updateSequenceLists(rowData.id, rowData.groupName, rowData.remark, rowData.progressPointName)
      .subscribe((response: any) => {
        this.spinner = false;
        this.getSequence();
      }, (error) => {
        this.spinner = false;
      });
  }

  /**
   * download dummy file sample for import
   */
  downloadDummyFile() {
    window.open('../../assets/import/Import-operation-sequence.xlsx.xlsx', '_blank');
  }

  callOrderSequence(){
    if(this.changeSequence === true ){
      this.spinner = true;
      this.service.reOrderSequence(this.styleId, this.stageId, this.sequence).subscribe((response: any) => {
        this.spinner = false;
        this.getSequence();
      }, (error) => {
        this.spinner = false;
      });
      this.changeSequence = false;
    }
  }

  reOrderingSequence() {
    this.changeSequence = true;
    this.spinner = true;
    this.createDateToSend(this.sequence);
    this.spinner = false;
  }

  addOperationSequence() {
    this.changeSequence = true;
    this.spinner = true;
    this.createDateToSend(this.sequence);
    this.service.reOrderSequence(this.styleId, this.stageId, this.sequence).subscribe((response: any) => {
      this.spinner = false;
      this.getSequence();
    }, (error) => {
      this.spinner = false;
    });
    this.spinner = false;
  }

  /**
   * export operation sequence
   */
  exportOpSequence() {
    this.spinner = true;
    this.service.exportSequence(this.createSequence.factoryId, this.styleId, this.stageId).subscribe((response: any) => {
      if (response.success === false) {
        this.toastr.error(response.message, 'error');
        this.spinner = false;
      } else {
        const fileNameTemp = response.data != null && response.data.indexOf('-') > -1 ?
        response.data.substring(response.data.indexOf('-') + 1) : response.data + '.xlsx';
        this.downloadFile(response.data, fileNameTemp);
      }
    }, (error) => {
      this.spinner = false;
      this.toastr.error(error, 'error');
    });
  }

  downloadFile(fileName: string, fileDownloadName) {
    this.service.downloadFile(fileName).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });

      const downloadURL = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileDownloadName;
      link.click();
      this.spinner = false;
    }, (error) => {
      this.spinner = false;
    });
  }

  /**
   * fetch the status of style
   */
  getApprovedStatus() {
    // this.spinner = true;
    this.service.getApproveStatus(this.styleId, this.stageId).subscribe((response: any) => {
      this.styleStatus = response.data ? response.data : '';
      // this.spinner = false;
    });
  }

  /**
   * get operation sequence print
   */
  sequencePrint() {
    this.spinner = true;
    this.service.getSequencePrint(this.createSequence.factoryId, this.styleId, this.stageId).subscribe((response: any) => {
      const fileNameTemp = response.data != null && response.data.indexOf('-') > -1 ?
       response.data.substring(response.data.indexOf('-') + 1) : response.data + '.pdf';
      this.downloadFile(response.data, fileNameTemp);
    }, (error) => {
      this.spinner = false;
    });
  }

  sequencePlanningPrint() {
    this.spinner = true;
    this.service.getSequencePlanningPrint(this.createSequence.factoryId, this.styleId, this.stageId).subscribe((response: any) => {
      if (response.success === false) {
        this.toastr.error(response.message, 'error');
        this.spinner = false;
      } else {
        const fileNameTemp = response.data != null && response.data.indexOf('-') > -1 ?
        response.data.substring(response.data.indexOf('-') + 1) : response.data + '.pdf';
        this.downloadFile(response.data, fileNameTemp);
      }
    }, (error) => {
      this.spinner = false;
      this.toastr.error(error, 'error');
    });
  }

  /**
   * On change of subsection
   */
  onChangeSubsection(ev, index) {
    this.sequence[index].subSectionName = ' ';
    let exists = false;
    if (ev.value !== 'Choose' && ev.value !== '') {
      for (const data of this.sequence) {
        if (data.subSectionName === ev.value || data.part === ev.value ||
          (data.operation && data.operation.partsDTO && data.operation.partsDTO.name === ev.value)) {
          exists = true;
          this.toastr.error('already selected', 'Failed');
          this.sequence[index].subSectionName = ' ';
          break;
        }
      }
      if (!exists) {
        alert('not exist');
      }
    } else {
      alert('exist');
    }
  }

  /**
   * on Select subsection with auto complete
   */
  capturetoSubsection(event, index) {
    let subsectionId = null;
    for (const styleData of this.subsectionList) {
      if (styleData.subSectionName === event) {
        subsectionId = styleData.subSectionId;
        break;
      }
    }
    this.updateSubSection(index, subsectionId);
  }

  /**
   * Update Sub section on change
   * @param index  rowIndex
   * @param subsectionId selected subsection id
   */
  updateSubSection(index, subsectionId) {
   this.service.updateSubSection(this.sequence[index].id, subsectionId).subscribe((response: any)=>{
    this.toastr.success('Updated Successfully', 'Success');
   });
  }

  /**
   * On Search Subsection
   */
  toSearchSubsection(event) {
    this.service.searchSubsection(event.query, this.stageId, this.styleId).subscribe((response: any) => {
      const subsectionList = [];
      for (const style of response.data) {
        subsectionList.push(style.subSectionName);
      }
      this.subsectionListOptions = subsectionList.length > 0 ? subsectionList : ['No Styles Found'];
      this.subsectionList = response.data;
    });
  }
}