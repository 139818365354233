import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
    providedIn: 'root'
})
export class SequenceService {
    constructor(
        private http: HttpClient,
        private httpClientService: HttpclientService,
        private httpService: HttpClient
    ) { }

    searchStyles(styleName: any, factoryId: any) {
        const listOfStyle = this.httpService
            .get(`/apigateway/operation/api/v1/autocomplete/style?styleName=${styleName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * search description
     * @param descName to be search
     * @param factoryId fetch description related to this factory
     */
    searchDesc(descName, factoryId) {
        const listOfDesc = this.httpService
            .get(`/apigateway/operation/api/v1/operations/description?description=${descName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
                })
            );
        return listOfDesc;
    }

    /**
     * search operation
     * @param operationName to be search
     * @param factoryId fetch description related to this factory
     */
    searchOperation(operationName, factoryId) {
        const listOfOperations = this.httpService
            .get(`/apigateway/operation/api/v1/operations/operationNumber?operationNumber=${operationName}&factoryId=${factoryId}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
                })
            );
        return listOfOperations;
    }
    getFactoryId() {
        const url = `${window.location.origin}/apigateway/style/api/v1/factories/user`;
        return this.httpClientService.get(url);
    }

    getStages() {
        const url = `${window.location.origin}/apigateway/operation/api/v1/get/stages`;
        return this.httpClientService.get(url);
    }

    getSequence(styleId: any, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operations/style?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * saving updated operation sequence list on server
     * @param sequenceList to be save on server
     */
    updateSequenceList(styleId, sequenceList, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/create/operation/sequences?styleId=
    ${styleId}&stageId=${stageId}&delete=true`;
        return this.httpClientService.post(url, sequenceList);
    }

    /**
     * copy operation sequence list to another style
     */
    copySequenceList(tostyleId, selectedList) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/copy/operation/sequences/part?toStyleId=${tostyleId}`;
        return this.httpClientService.post(url, selectedList);
    }

    /**
     * send style for Approval
     */
    sendForApproval(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/send/style/for/approval?styleId=
    ${styleId}&stageId=${stageId}`;
        return this.httpClientService.put(url, {});
    }

    /**
     * Approve Sequence style
     */
    approveSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/approve/style?styleId=
    ${styleId}&stageId=${stageId}`;
        return this.httpClientService.put(url, {});
    }
    disApproveSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/disapprove/style?styleId=
    ${styleId}&stageId=${stageId}`;
        return this.httpClientService.put(url, {});

    }

    // styleId is called in history
    getHistorySequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/obhistory?styleId=
    ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    getVersionsSequence(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/opseqcosting/versionlist/style?styleId=
    ${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    getVersions(styleId, version, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/opseqcosting/style/version?styleId=
    ${styleId}&version=${version}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    getPlannedStyle(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/style/api/v1/get/style/planning?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    getFactorySettings(factoryId: Number) {
        const url = `${window.location.origin}/apigateway/admin/api/v1/get/setting?factoryId=${factoryId}`;
        return this.httpClientService.get(url);
    }

    updatePlanningStyle(data) {
        const url = `${window.location.origin}/apigateway/style/api/v1/style/planning`;
        return this.httpClientService.post(url, data);
    }
    importFile(formData, importData) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/data/operation/import?factoryId=
    ${importData.factoryId}&styleId=${importData.styleId}&stageId=${importData.stageId}`;
        return this.httpClientService.post(url, formData);
    }
    reOrderSequence(styleId, stageId, sequenceList) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/sequences?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.put(url, sequenceList);
    }
    deleteSequence(styleId, stageId,isAllDeleted, sequenceList) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/sequences?styleId=${styleId}&stageId=${stageId}&isAllDeleted=${isAllDeleted}`;
        return this.httpClientService.remove(url, sequenceList);
    }
    updateSequenceLists(id, groupName, remark, progressPoint) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/sequences/${id}?groupName=
    ${groupName}&remark=${remark}&progressPoint=${progressPoint}`;
        return this.httpClientService.put(url, {});
    }
    exportSequence(factoryId, styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/data/operation/export?factoryId=
    ${factoryId}&styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    downloadFile(fileName) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/operation/download/${fileName}`;
        return this.httpClientService.getFile(url);
    }
    getApproveStatus(styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/sequence/status?styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }
    getSequencePrint(factoryId, styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/data/operation/print?factoryId=${factoryId}&styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    getSequencePlanningPrint(factoryId, styleId, stageId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/data/planning/print?factoryId=${factoryId}&styleId=${styleId}&stageId=${stageId}`;
        return this.httpClientService.get(url);
    }

    /**
     * 
     */
    searchSubsection(searchQuery, stageId, styleId) {
        const listOfStyle = this.httpService
            .get(`/apigateway/operation/api/v1/get/${styleId}/subSection/${stageId}/${searchQuery}`)
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length !== 0 ? (data as any[]) : [{ 'Role Name': 'No Role Found' } as any];
                })
            );
        return listOfStyle;
    }

    /**
     * updateSubSection
     */
    updateSubSection(sequenceId, subSectionId) {
        const url = `${window.location.origin}/apigateway/operation/api/v1/updateSubsection/${sequenceId}/${subSectionId}`;
        return this.httpClientService.put(url, {});
    }
}
