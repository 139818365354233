import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { trigger, transition, useAnimation, style, animate, state, group } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OperationService } from './operation.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { operationsColumns } from './operation-list.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { color } from '@amcharts/amcharts4/core';
import { reduce } from 'rxjs/operators';
import { SlideInOutAnimation } from '../animations/animation';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-operations',
    templateUrl: './operations.component.html',
    styleUrls: ['./operations.component.sass', './operations.component.css'],
    animations: [
        trigger('flip', [transition('* => *', useAnimation(flipInY))]),
        trigger('bounce', [transition('* => *', useAnimation(bounce))]),
        trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
        trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
        trigger('rowExpansionTrigger', [
            state('void', style({
                transform: 'translateX(-10%)',
                opacity: 0
            })),
            state('active', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ]),
        SlideInOutAnimation
    ]
})
export class OperationsComponent implements OnInit {
    displayMode = 1;
    flip: any;
    bounce: any;
    factoryIdDTO: any;
    columns: any;
    stitchIdDTO: any;
    bounceIn: any;
    createStitch: any = {};
    rollIn: any;
    results: Array<any> = [];
    coList: any;
    machineId: number;
    optionSelected: string;
    createMachine: any = {};
    createOperation: any = {};
    operationUpdate: any = {};
    sales: any[];
    cols: any[];
    factoryId: number;
    operationId: number;
    resultsMaterial: Array<any> = [];
    resultsGrade: Array<any> = [];
    resultsProduct: Array<any> = [];
    resultsOperation: Array<any> = [];
    resultsPart: Array<any> = [];
    resultsSkill: Array<any> = [];
    resultsStyle: Array<any> = [];
    operationList: any;
    materialList: any;
    gradeList: any;
    productList: any;
    partList: any;
    skillList: any;
    styleList: any;
    styleId: any;
    skillDTO: any;
    styleSelect = false;
    urls: any = [];
    searchBy: string;
    spinner = true;
    transformStyle: {};
    opaerationCartList: Array<any> = [];
    animateComplete: boolean;
    cartStyle: any;
    createGrade: any = {};
    stageId: number;
    animationState = 'out';
    showSearchStyle: boolean;
    stageList: Array<any> = [];
    uploadImgUrl: string;
    uploadedImages: Array<any> = [];
    uploadData: any = {};
    beforeVideo: string;
    afterVideo: string;
    gradeLists: Array<any> = [];
    editGradeData: any = {};
    operationData: Array<any> = [];
    machineAuthData: any;
    opAuthData: any = {};
    opHistoryList: Array<any> = [];
    documentUploadURL: string;
    styleStageList: Array<any> = [];
    workAidList: Array<any> = [];
    searchedOperationDebounce = _.debounce(this.debounceSearch, 700);
    @ViewChild('mainCart') mainCart: any;
    @ViewChild('opNos') opNos: any;
    @ViewChild('op') op: any;
    @ViewChild('salesTypeId') salesTypeId: any;
    @ViewChild('total1Ref') total1Ref: any;

    // show = false;
    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private operationService: OperationService,
        private httpClient: HttpClient,
        private toastr: ToastrService,
        private service: OperationService,
        public sharedService: SharedService,
        public elRef: ElementRef,
        public activatedRoute: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.uploadImgUrl = `${window.location.origin}/apigateway/operation/api/v1/operations/image`;
        this.beforeVideo = `${window.location.origin}/apigateway/operation/api/v1/operations/video?usedType=BEFORE`;
        this.afterVideo = `${window.location.origin}/apigateway/operation/api/v1/operations/video?usedType=AFTER`;
        this.documentUploadURL = `${window.location.origin}/apigateway/operation/api/v1/operations/document`;
        this.optionSelected = 'opNo';
        this.createMachine.machineName = null;
        this.createMachine.factoryId = null;
        this.createMachine.machineColor = null;
        this.createMachine.stitchClassId = null;
        this.createMachine.rpm = null;
        this.createMachine.allowance = null;
        this.createMachine.machineType = null;
        this.createOperation.material = null;
        this.createOperation.styleName = null;
        this.createOperation.product = null;
        this.createOperation.parts = null;
        this.createOperation.opTypes = null;
        this.createOperation.machineId = this.machineId;
        this.createOperation.factoryId = this.factoryId;
        this.createOperation.skill = null;
        this.createOperation.grade = null;
        this.createOperation.seamLength = null;
        this.createOperation.description = null;
        this.createOperation.stdTime = null;
        this.createOperation.operationId = null;
        this.createStitch.factoryId = null;
        this.searchBy = '';
        // this.cols = this.operationList;
    }

    ngOnInit() {
        this.getFactoryId();
        this.getStitchClass();
        this.getStages();
        this.getAuthData();
        this.cols = operationsColumns;
    }

    /**
     * fetch role and permissions
     */
    getAuthData() {
        // this.spinner = true;
        const auth = this.sharedService.authData$.subscribe((data: any) => {
            const authData = data.find((el) => {
                return el.name === 'Operation';
            });
            const machineAuthData = authData.subModules.find((el) => {
                return el.subModuleName === 'Machine';
            });
            const opAuthData = authData.subModules.find((el) => {
                return el.subModuleName === 'Operation';
            });
            this.machineAuthData = machineAuthData;
            this.opAuthData = opAuthData;
            // this.spinner = false;
        });
    }

    oncreateMachine(form: NgForm) {
        this.operationService.createMachine(this.createMachine).subscribe(
            result => {
                form.reset();
                this.toastr.success(result.message, 'Success');
            },
            error => {
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    openImageUploadModal(opData) {
        this.uploadedImages = [];
        this.uploadData['operationId'] = opData.operationId;
        this.uploadData['opNo'] = opData.opNo;
        this.uploadData['desc'] = opData.desc;
        const Documents = opData.documents ? opData.documents : [];
        for (const iterator of Documents) {
            if (iterator.documentType === 'IMAGE') {
                this.uploadedImages.push(iterator);
            }
        }
        this.ngxSmartModalService.getModal('myModal12').open();
    }
    openVideoUploadModal(opData) {
        this.uploadedImages = [];
        const Documents = opData.documents ? opData.documents : [];
        for (const iterator of Documents) {
            if (iterator.documentType === 'VIDEO') {
                this.uploadedImages.push(iterator);
            }
        }
        this.uploadData['operationId'] = opData.operationId;
        this.uploadData['opNo'] = opData.opNo;
        this.uploadData['desc'] = opData.desc;
        this.ngxSmartModalService.getModal('myModal11').open();
    }

    openFolderUploadModal(opData) {
        this.uploadedImages = [];
        this.uploadData['operationId'] = opData.operationId;
        this.uploadData['opNo'] = opData.opNo;
        this.uploadData['desc'] = opData.desc;
        const Documents = opData.documents ? opData.documents : [];
        for (const iterator of Documents) {
            if (iterator.documentType === 'DOCUMENT') {
                iterator.name = iterator.name.substring(iterator.name.indexOf('-') + 1);
                this.uploadedImages.push(iterator);
            }
        }
        this.ngxSmartModalService.getModal('myModal14').open();
    }

    // onWorkAidModal() {
    //   this.ngxSmartModalService.getModal('workAidModal').open();
    // }

    styleInput() {
        this.showSearchStyle = true;
        // const checkBox = document.getElementById('myCheck') as HTMLInputElement;
        // // tslint:disable-next-line:prefer-const
        // const text = document.getElementById('text') as HTMLInputElement;
        // if (checkBox.checked === true) {
        //   text.style.display = 'block';
        // } else {
        //   text.style.display = 'none';
        // }
    }

    oncreateOperation() {
        this.spinner = true;
        // this.factoryId = this.createOperation.factoryId;
        // this.styleId = this.resultsStyle;
        this.createOperation.machineId = this.machineId;
        this.createOperation.styleId = this.styleId;
        this.createOperation.stageId = this.stageId;
        this.createOperation.addToStyle = this.cartStyle ? true : false;
        this.operationService.createOperations(this.createOperation, this.factoryId, this.styleId, this.cartStyle, this.stageId).subscribe(
            result => {
                this.createOperation.seamLength = null;
                this.createOperation.description = null;
                this.createOperation.stdTime = null;
                this.getAllOperations();
                this.spinner = false;
                this.toastr.success(result.message, 'Success');
            },
            error => {
                this.spinner = false;
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    onCompanyUpdate(form: NgForm) {
        this.factoryId = this.operationUpdate.factoryId;
        this.operationId = this.operationUpdate.operationId;
        this.operationUpdate.machineId = this.machineId;
        this.operationService.updateOperation(this.operationUpdate, this.factoryId, this.operationId).subscribe(
            result => {
                form.reset();
                this.toastr.success(result.message, 'Success');
                this.getAllOperations();
            },
            error => {
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    /**
     * on checkout cart
     */
    checkoutCart() {
        this.spinner = true;
        this.operationService.cartOperations(this.opaerationCartList, this.styleId, this.stageId).subscribe(
            result => {
                this.spinner = false;
                this.toastr.success(result.message, 'Success');
                this.opaerationCartList = [];
            },
            error => {
                this.spinner = false;
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }


    addToCartOperation(list, rowIndex, ev) {
        const x = this.mainCart.nativeElement.x - this.opNos.nativeElement.x;
        const y = this.mainCart.nativeElement.y - this.opNos.nativeElement.y;
        this.total1Ref.nativeElement.innerText = list.opNo;
        const a = 35;
        const b = this.opNos.nativeElement.getBoundingClientRect().top;
        this.transformStyle = {
            top: `${this.opNos.nativeElement.offsetTop + (27 * (rowIndex + 1))}px`,
            'transform': `translate(${x}px, ${y}px)`,
            transition: 'all 1s ease-in-out',
            'z-index': '9999',
        };
        setTimeout(() => {
            this.transformStyle = {
                // top: `0px`,
                position: 'absolute',
                transition: 'none',
                'z-index': '-1',
                // 'opa': 'none',
            };
            this.animateComplete = true;
        }, 1000);
        const operationExists = this.opaerationCartList.some((x) => {
            return x.operationId === list.operationId;
        });
        if (!operationExists) {
            this.opaerationCartList.push({ operationId: list.operationId, styleId: list.partsDTO.id });
        }
        // this.moveCat();
        // this.styleId = this.resultsStyle;
        // this.operationId = list.operationId;
        // this.operationService.addOperationToCart(this.operationId, this.styleId).subscribe(
        //   result => {
        //     this.toastr.success(result.message, 'Success');
        //     this.getAllOperations();
        //   },
        //   error => {
        //     this.toastr.error(error.error.message, 'Error');
        //   }
        // );
    }

    onSelectImage(event) {
        this.spinner = true;
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            this.httpClient.post<any>(this.uploadImgUrl, formData).subscribe(
                (res) => {
                    this.spinner = false;
                    this.uploadedImages.push(res.data);
                },
            );
        }
    }

    /**
     * Save image and Video
     */
    UploadFiles() {
        delete this.uploadData['opNo'];
        delete this.uploadData['desc'];
        this.uploadData['documentIds'] = this.uploadedImages.map(x => x.id);
        this.operationService.uploadOperationDocs(this.uploadData).subscribe(
            result => {
                this.spinner = false;
                this.toastr.success(result.message, 'Success');
                this.getAllOperations();
                this.uploadedImages = [];
            },
            error => {
                this.spinner = false;
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    /**
     * upload images
     */
    UploadDocs() {
        //   this.operationService.uploadOperationDocs(this.uploadData).subscribe(
        //     result => {
        //       this.spinner = false;
        //       this.toastr.success(result.message, 'Success');
        //       this.getAllOperations();
        //       this.uploadedImages = [];
        //     },
        //     error => {
        //       this.spinner = false;
        //       this.toastr.error(error.error.message, 'Error');
        //     }
        //   );
        // }
    }

    /**
     * on image upload
     */

    /**
     * on doc upload
     */
    onDocumentUpload(ev) {
        // const response = event.originalEvent.body;
        const response = JSON.parse(ev.xhr.response);
        this.spinner = false;
        this.uploadedImages.push(response.data);
    }

    //  onBasicUpload(ev) {
    //   const response = JSON.parse(ev.xhr.response);
    //   this.spinner = false;
    //   this.uploadedImages.push(response.data);

    // }

    // setAuthToken(event) {
    //   this.spinner = true;
    //   const authToken = localStorage.getItem('authenticationToken');
    //   event.xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
    // }

    setAuthToken(event: any) {
        this.spinner = true;
        const authToken = localStorage.getItem('authenticationToken');
        if (event && event.xhr) {
            event.xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
        }
    }

    onBasicUpload(event: any) {
        try {
            const response = event.originalEvent.body;
            this.spinner = false;
            this.uploadedImages.push(response.data);
        } catch (error) {
            console.error('Error parsing upload response:', error);
            this.spinner = false;
        }
    }

    onCopyOperation(list) {
        this.operationService.copyOperation(list.operationId).subscribe(
            result => {
                this.toastr.success(result.message, 'Success');
                this.getAllOperations();
                if (this.optionSelected === 'opNo') {
                    this.searchBy = result.data.toString();
                }
            },
            error => {
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    deleteOperationList(list) {
        this.translate.get(['DELETE_CONFIRM_TITLE', 'DELETE_CONFIRM_TEXT', 'DELETE_CONFIRM_BUTTON', 'DELETE_CANCEL_BUTTON', 'DELETE_SUCCESS', 'FILE_DELETED']).subscribe(translations => {
            Swal.fire({
                title: translations['DELETE_CONFIRM_TITLE'],
                text: translations['DELETE_CONFIRM_TEXT'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: translations['DELETE_CONFIRM_BUTTON'],
                cancelButtonText: translations['DELETE_CANCEL_BUTTON']
            }).then(result => {
                if (result.value === true) {
                    this.operationService.deleteOperation(list.operationId).subscribe((response: any) => {
                        const index: number = this.sales.indexOf(list);
                        this.sales.splice(index, 1);
                        Swal.fire(translations['DELETE_SUCCESS'], translations['FILE_DELETED'], 'success');
                        this.toastr.success(response.message, 'Success');
                    }, (error) => {
                        this.toastr.error(error.error.message, 'Error');
                    });
                }
            });
        })
    }

    search(event) {
        this.service.search(event.query).subscribe((response: any) => {
            const machineList = [];
            for (const comp of response.data) {
                comp.id = comp.id;
                machineList.push(comp.name);
                this.machineId = comp.id;
            }
            this.results = machineList;
            this.coList = response.data;
        });
    }

    captureId(event: any) {
        for (let i = 0; i < this.coList.length; i++) {
            if (this.coList[i].name === event) {
                this.machineId = this.coList[i].id;
            }
        }
    }

    searchMaterial(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchMaterial(event.query, this.factoryId).subscribe((response: any) => {
            const materialList = [];
            for (const material of response.data) {
                material.name = material.name;
                materialList.push(material.name);
                // this.companyId = comp.id;
            }
            this.resultsMaterial = materialList;
            this.materialList = response.data;
        });
    }

    captureMaterial(event: any) {
        for (let i = 0; i < this.materialList.length; i++) {
            if (this.materialList[i].name === event) {
                this.resultsMaterial = this.materialList[i].id;
            }
        }
    }

    searchGrade(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchGrade(event.query, this.factoryId).subscribe((response: any) => {
            const gradeList = [];
            for (const grade of response.data) {
                grade.name = grade.name;
                gradeList.push(grade.name);
                // this.companyId = comp.id;
            }
            this.resultsGrade = gradeList;
            this.gradeList = response.data;
        });
    }

    captureGrade(event: any) {
        for (let i = 0; i < this.gradeList.length; i++) {
            if (this.gradeList[i].name === event) {
                this.resultsGrade = this.gradeList[i].id;
            }
        }
    }

    searchProduct(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchProduct(event.query, this.factoryId).subscribe((response: any) => {
            const productList = [];
            for (const product of response.data) {
                product.name = product.name;
                productList.push(product.name);
                // this.companyId = comp.id;
            }
            this.resultsProduct = productList;
            this.productList = response.data;
        });
    }

    captureProduct(event: any) {
        for (let i = 0; i < this.productList.length; i++) {
            if (this.productList[i].name === event) {
                this.resultsProduct = this.productList[i].id;
            }
        }
    }

    searchOperation(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchOpTypes(event.query, this.factoryId).subscribe((response: any) => {
            const operationList = [];
            for (const product of response.data) {
                product.name = product.name;
                operationList.push(product.name);
                // this.companyId = comp.id;
            }
            this.resultsOperation = operationList;
            this.operationList = response.data;
        });
    }

    captureOperation(event: any) {
        for (let i = 0; i < this.operationList.length; i++) {
            if (this.operationList[i].name === event) {
                this.resultsOperation = this.operationList[i].id;
            }
        }
    }

    searchParts(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchParts(event.query, this.factoryId).subscribe((response: any) => {
            const partList = [];
            for (const part of response.data) {
                part.name = part.name;
                partList.push(part.name);
                // this.companyId = comp.id;
            }
            this.resultsPart = partList;
            this.partList = response.data;
        });
    }

    captureParts(event: any) {
        for (let i = 0; i < this.partList.length; i++) {
            if (this.partList[i].name === event) {
                this.resultsPart = this.partList[i].id;
            }
        }
    }

    searchSkill(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchSkills(event.query, this.factoryId).subscribe((response: any) => {
            const skillList = [];
            for (const skill of response.data) {
                skill.name = skill.name;
                skillList.push(skill.name);
                // this.companyId = comp.id;
            }
            this.resultsSkill = skillList;
            this.skillList = response.data;
        });
    }

    captureSkill(event: any) {
        for (let i = 0; i < this.skillList.length; i++) {
            if (this.skillList[i].name === event) {
                this.resultsSkill = this.skillList[i].id;
            }
        }
    }

    searchStyle(event) {
        // this.factoryId = this.createOperation.factoryId;
        this.service.searchStyles(event.query, this.factoryId).subscribe((response: any) => {
            const styleList = [];
            for (const style of response.data) {
                style.name = style.name;
                styleList.push(style.name);
                // this.companyId = comp.id;
            }
            this.resultsStyle = styleList;
            this.styleList = response.data;
        });
    }

    captureStyle(event: any) {
        for (let i = 0; i < this.styleList.length; i++) {
            if (this.styleList[i].name === event) {
                this.styleId = this.styleList[i].id;
                this.cartStyle = this.styleList[i].id;
            }
        }
    }

    /**
     * sort table data
     */
    sortTable() {
        if (this.optionSelected === 'opNo') {
            this.sales = this.sales.sort((a, b) => {
                return a.opNo - b.opNo;
            });
        } else {
            this.sales = this.sales.sort((a, b) => {
                const nameA = a[this.optionSelected] ? a[this.optionSelected].toUpperCase() : a[this.optionSelected].toUpperCase();
                const nameB = b[this.optionSelected] ? b[this.optionSelected].toUpperCase() : b[this.optionSelected].toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        }
        this.searchOpDesc(this.searchBy);
    }

    /**
     * Debounce Operation Search
     * @param event selected value
     */
    debounceSearch(event) {
        this.operationService.searchOperations(this.factoryId, event, this.optionSelected).subscribe((response: any) => {
            console.log('response', response);
            this.sales = response.data;
            for (const data of this.sales) {
                data.machine = data.machineDTO ? data.machineDTO.name : '';
                data.opType = data.opTypesDTO ? data.opTypesDTO.name : '';
                data.skill = data.skillDTO ? data.skillDTO.name : '';
                data.grade = data.gradeDTO ? data.gradeDTO.name : '';
                data.productName = data.productDTO ? data.productDTO.name : '';
                if (data.documents.length > 0) {
                    const image = data.documents.some((x) => x.documentType === 'IMAGE');
                    const video = data.documents.some((x) => x.documentType === 'VIDEO');
                    data.isImage = image;
                    data.isVideo = video;
                } else {
                    data.isImage = false;
                    data.isVideo = false;
                }
            }
            this.operationData = this.sales;
        });
    }

    /**
     * search table data
     */
    searchOpDesc(event) {
        const keyToMatch = this.optionSelected;
        this.sales = this.operationData.filter(item => {
            if (item[keyToMatch].toString().toLowerCase().indexOf(event.toLowerCase()) !== -1) {
                return true;
            }
            return false;
        });
        // this.service.searchByDescOP(event, this.factoryId).subscribe((response: any) => {
        //   this.sales = response.data;
        // });
    }

    public openNewMachineModal() {
        this.ngxSmartModalService.getModal('myModal').open();
        this.getAllOperations();
    }

    openNewGradeModal() {
        this.getGrade();
        this.ngxSmartModalService.getModal('gradeModal').open();
    }

    openOpHistory(operationData) {
        this.getOPHistory(operationData.operationId);
        this.ngxSmartModalService.getModal('opHistory').open();
    }

    getOPHistory(opId) {
        this.spinner = true;
        this.operationService.getOpHistory(opId).subscribe((response: any) => {
            this.opHistoryList = response.data;
            this.spinner = false;
        }, (error) => {
            this.spinner = false;
        });
    }

    openGradeModal(data) {
        this.editGradeData = data;
        this.ngxSmartModalService.getModal('editGradeModal').open();
    }

    public openNewStichModal() {
        this.ngxSmartModalService.getModal('myModal1').open();
        this.getStitchClass();
    }

    public onOperationEdit(data) {
        this.ngxSmartModalService.getModal('myModal4').open(data);
        this.operationUpdate.factoryId = data.factoryDTO.id;
        this.operationUpdate.material = data.materialDTO.name;
        this.operationUpdate.product = data.productDTO.name;
        this.operationUpdate.parts = data.partsDTO.name;
        this.operationUpdate.opTypes = data.opTypesDTO.name;
        this.operationUpdate.machineId = data.machineDTO.id;
        this.machineId = data.machineDTO.id;
        this.operationUpdate.machineName = data.machineDTO.name;
        this.operationUpdate.skill = data.skillDTO.name;
        this.operationUpdate.grade = data.gradeDTO.name;
        this.operationUpdate.seamLength = data.sLength;
        this.operationUpdate.description = data.desc;
        this.operationUpdate.stdTime = data.stdMin;
        this.operationUpdate.operationId = data.operationId;
        this.operationUpdate.workAid = data.workAid;
        this.operationUpdate.status = data.status;
        this.getAllOperations();
    }

    getStitchClass() {
        // this.spinner = true;
        this.operationService.getStitchClass().subscribe((response: any) => {
            this.stitchIdDTO = response.data;
            // this.spinner = false;
        });
    }

    getAllOperations() {
        this.spinner = true;
        this.operationService.getOperations(this.factoryId).subscribe((response: any) => {
            if (response.data) {
                this.sales = response.data;
                for (const data of this.sales) {
                    data.machine = data.machineDTO ? data.machineDTO.name : '';
                    data.opType = data.opTypesDTO ? data.opTypesDTO.name : '';
                    data.skill = data.skillDTO ? data.skillDTO.name : '';
                    data.grade = data.gradeDTO ? data.gradeDTO.name : '';
                    data.productName = data.productDTO ? data.productDTO.name : '';
                    if (data.documents.length > 0) {
                        const image = data.documents.some((x) => x.documentType === 'IMAGE');
                        const video = data.documents.some((x) => x.documentType === 'VIDEO');
                        data.isImage = image;
                        data.isVideo = video;
                    } else {
                        data.isImage = false;
                        data.isVideo = false;
                    }
                }
                this.operationData = this.sales;
                this.sortTable();
                if (this.searchBy) {
                    this.searchOpDesc(this.searchBy);
                }
                this.spinner = false;
            } else {
                this.spinner = false;
            }
        });
    }

    getFactoryId() {
        // this.spinner = true;
        this.operationService.getFactoryId().subscribe((response: any) => {
            this.factoryIdDTO = response.data;
            this.factoryId = this.factoryIdDTO[0].id;
            this.getAllOperations();
            // this.spinner = false;
        });
    }

    /**
     * get Stages
     */
    getStages() {
        // this.spinner = true;
        this.operationService.getStages().subscribe((response: any) => {
            this.stageList = response.data;
            this.stageId = this.stageList[0].id;
            // this.spinner = false;
        });
    }

    oncreateStitch(form: NgForm) {
        this.operationService.createStitch(this.createStitch).subscribe(
            result => {
                form.reset();
                this.toastr.success(result.message, 'Success');
                this.getStitchClass();
            },
            error => {
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    /**
     * show or translate add operation section
     */
    toggleShowDiv(divName: string) {
        if (divName === 'divA') {
            this.animationState = this.animationState === 'out' ? 'in' : 'out';
        }
    }

    /**
     * creating grade
     */
    oncreateGrade() {
        this.spinner = true;
        this.operationService.createGrade(this.factoryId, this.createGrade).subscribe(
            result => {
                this.resetGradePopUP();
                this.toastr.success(result.message, 'Success');
            },
            error => {
                this.resetGradePopUP();
                this.toastr.error(error.error.message, 'Error');
            }
        );
    }

    /**
     * close and reset Grade Popup
     */
    resetGradePopUP() {
        this.spinner = false;
        this.createGrade = {};
        this.getGrade();
    }

    /**
     * get grade List
     */
    getGrade() {
        this.operationService.getGrade(this.factoryId).subscribe((response: any) => {
            this.gradeLists = response.data;
        });
    }

    /**
     * edit grade
     */
    editGrade() {
        this.spinner = true;
        this.operationService.updateGrade(this.editGradeData).subscribe((response: any) => {
            this.spinner = false;
            this.ngxSmartModalService.getModal('editGradeModal').close();
            this.toastr.success(response.message, 'Success');
            this.getGrade();
        });
    }

    /**
     * delete grade
     */
    deleteGrade(gradeId) {
        this.spinner = true;
        this.operationService.deleteGrade(gradeId).subscribe((response: any) => {
            this.spinner = false;
            this.toastr.success(response.message, 'Success');
            this.getGrade();
        });
    }

    /**
     * delete document
     */
    deleteDocument(item) {
        this.operationService.deleteDoc(this.uploadData.operationId, item.id).subscribe((response: any) => {
            this.uploadedImages.splice(this.uploadedImages.indexOf(item.id), 1);
            this.toastr.success(response.message, 'Success');
            this.getAllOperations();
        });
    }

    /**
     * To open style details modal
     * @param operationData selected OP
     */
    openOpStyleDetails(operationData) {
        this.spinner = true;
        this.getStyleStageList(operationData.operationId);
        this.ngxSmartModalService.getModal('styleDetails').open();
    }
    /**
     * To get the list of style and stage
     */
    getStyleStageList(operationId) {
        this.operationService.getStyleStageList(operationId).subscribe((response: any) => {
            this.styleStageList = response.data;
            this.spinner = false;
        }, (error) => {
            this.toastr.success('Something went wrong', 'error');
            this.spinner = false;
        });
    }

    /**
     * To Search WorkAid 
     * @param event 
     */
    searchWorkAid(event) {
        // this.factoryId = this.createOperation.factoryId;
        // this.workAidList = [];
        this.service.searchWorkAid(event.query, this.factoryId).subscribe((response: any) => {
            this.workAidList = response.data ? response.data : [];
        });
    }

    /**
     * On select Work Aid
     * @param event 
     */
    captureWorkAid(event: any) {
        this.createOperation.workAid = event;
    }
}
